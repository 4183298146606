* {
  box-sizing: border-box;
}

body {
  font-family: Inter;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance:none;
    -webkit-appearance: none;
    margin: 0;
  background-color:transparent;
}

input[type=number] {
  appearance:none;
    -moz-appearance: textfield;
  background-color:transparent;
}

input[type=text] {
  appearance:none;
    -moz-appearance: textfield;
  background-color:transparent;
}

input[type=date] {
  appearance:none;
    -moz-appearance: textfield;
  background-color:transparent;
}

select {
  appearance:none;
  -moz-appearance:none;
  -webkit-appearance:none;
  background-color:transparent;
}

select::-ms-expand {
  display:none;
}

canvas {
  margin: 0 auto;
}
